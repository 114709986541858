import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0037 = () => (
  <Wrapper>
    <ColumnH1 label="通勤手当とは？基本的な考え方と支給の形態について解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.11.28</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        コロナ禍を経て、働き方が変革を迎えています。これまで毎日出勤をしながら対面業務一択であった企業も特定のエッセンシャルワーカーを除き、テレワークを採用し、感染拡大防止に寄与する動き方が通常となっています。
        <br />
        <br />
        今回は、交通費に関して、時代の流れも汲みながら基本的な内容にフォーカスをあて、解説していきます。
      </p>
      <img src="/images/column/details/c0037.jpg" alt="キンクラコラム画像37" />
      <h1>通勤手当とは</h1>
      <p>
        起居寝食する自宅から労務の提供場所（通常は会社）までの
        <span>通勤に要する費用</span>
        を手当として、支給することを指します。最低賃金はあっても労働基準法上、通勤手当の支払い義務はありませんので、通勤手当を設けるか否かは会社の裁量に委ねられます。
        <br />
        しかし、新たに人材を募集する際に求職者目線では通勤手当が支給されないという会社が通勤手当ありの会社に比べてどのように映るのかは考慮すべきです。
        <br />
        実際に労働者から申請される通勤手当は、複数の経路が想定されることが多く、どのような経路で認定するかも会社の裁量に委ねられますが、一般的には
        <span>経済的かつ合理的な経路</span>
        で認定すべきです。それは、通勤時間が短縮するからと言っても、あまりにも手当が高額化する場合、労働者側のメリットと比べて、会社の負担が大きすぎるためです。
      </p>
      <h1>出張費とは</h1>
      <p>
        業務命令により、地方へ出張する際に<span>「出張費」</span>
        として、一定の金銭を付与することです。
        <br />
        前提として、業務命令があることが要件ですので、労働者の主観で好き勝手に移動するような場合は出張費の支給は認められない旨を予め、周知しておくことが適切です。尚、出張費は、交通費の実費や、ホテルへの宿泊費用などが考えられます。
        <br />
        現在、新型コロナウイルスの感染拡大により出張自体は増えることは予想しづらいと言えますが、ゼロになるとまでは断言できないことから、会社として統一的なルールを整備しておく必要があります。
      </p>
      <h1>課税と非課税の関係</h1>
      <p>
        交通機関または有料道路を利用する場合に支給する通勤手当は1か月あたりの合理的な運賃等の額として、
        <span>15万円</span>
        と定められており、この額を超えると課税対象となります。
        <br />
        <br />
        次に自動車や自転車などの交通用具を使用している場合の通勤手当は、
        <span>距離に応じて、非課税限度額</span>
        が定められており、通勤距離が片道2キロメートル未満の場合に通勤手当を支給する場合は全額課税対象となります。そこから距離に応じて、段階的に非課税限度額が定められており、通勤距離が片道2キロメートル以上10キロメートル未満である場合、非課税限度額は4,200円となります。
      </p>
      <Introduction001 />
      <h1>社会保険上の通勤手当</h1>
      <p>
        税法上は一定額まで非課税となる通勤手当ですが、社会保険上の考え方では、通勤手当は課税対象である基本給などと同様に
        <span>「報酬」</span>
        として扱われます。よって、<span>「社会保険料の対象」</span>
        となる報酬の一つとして含まれますので、複数の労働者が仮に同じ基本給であっても自宅から会社までの距離が遠い労働者の方が社会保険料は高額化するという理解です。
        <br />
        また、雇用保険料の算定にあたっても、通勤手当を含めた額に保険料率を乗じて雇用保険料が計算されます。
      </p>
      <h1>支給の種類について</h1>
      <p>
        現在の一般的な慣行として、テレワークや通常の対面業務のみの企業によっても、支給の仕方が異なります。例えばテレワークが主体の企業であれば、オフィスへ出社した回数分のみ回数払いとして通勤手当を支給するという選択肢が挙げられます。例えば昨年までは、テレワークへの移行期であったため、従前から引き続き定期券代として、通勤手当を支給されていた場合、労働者の給与総支給額自体は減額することとなります。
        <br />
        しかし、通勤手当の支給趣旨を「実費補填」と位置付けている場合は、そもそも通勤手当で、「儲け」が出ること自体おかしな話ですので、
        <span>働き方の変化に伴い、実態に合った支給形態へ変更</span>
        したにすぎません。
        <br />
        <br />
        テレワークを実施していた企業が対面業務のみに戻るということは考え難く、テレワークでの一定の実績がある企業であればテレワークと対面業務のハイブリッド型の労務管理になることが予想され、言うまでもなく定期券代の支給のみでは、過剰支給となることから、議論の余地があります。
        <br />
        <br />
        また、定期代で支給する場合、1か月定期代で支給するのか、最長である6か月定期代で支給するのかも検討の余地があります。経営的な視点では1か月定期代で支給するより6か月定期代で支給する方が単価としては安価になりますが、途中退職者が出た場合の払い戻し手続きが煩雑になるというデメリットがあります。
      </p>
      <h1>最後に</h1>
      <p>
        通勤手当は、定めるか否かは会社の裁量に委ねられますが、支給するとした際には、
        <span>働き方に見合った支給方法</span>
        （定期代なのか回数払いなのか）、支給期間（定期券代支給月数）などは、予め決定しておくことが求められます。
        <br />
        また、法律上の取り扱いも税法上と社会保険上では取り扱いが異なる為に、
        <span>誤った労務管理とならないよう進めていく</span>ことが求められます。
        <br />
        また、同一労働同一賃金の観点からも雇用形態に応じて差を設ける場合、違法と判断されるリスクもあることから内容によっては専門家を交えて議論するという選択肢も有用です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0037
