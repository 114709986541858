import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0037 from '../../../components/molecules/columnDetailInner0037'
import UsefulFunctionLayout006 from '../../../components/molecules/usefulFunctionLayout006'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0037 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '交通費とは？各種手当について詳しく解説！',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="通勤手当とは？基本的な考え方と支給の形態について解説"
        description="通勤手当とは自宅から会社までの通勤に要する費用です。支給の際は働き方に見合った支給方法、支給期間などは、予め決定しておくことが求められます。"
        ogUrl="https://kintaicloud.jp/column/details/C0037/"
        ogType="article"
        ogTitle="通勤手当とは？基本的な考え方と支給の形態について解説"
        ogDescription="通勤手当とは自宅から会社までの通勤に要する費用です。支給の際は働き方に見合った支給方法、支給期間などは、予め決定しておくことが求められます。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0037.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0037 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout006 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0037

export const pageQuery = graphql`
  query C0037 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
